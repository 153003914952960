.about {
    padding: 100px 40px;
    background-color: #1a1a1a;
    color: white;
    text-align: center;
    border-bottom: 4px solid #070506f6;
}

.about-title {
    font-size: 3rem;
    margin-bottom: 50px;
    color: white;
    position: relative;
    display: inline-block;
}

.about-title::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 4px;
    background-color: #ff4d5a;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1400px;
    margin: 0 auto;
}

.left {
    flex: 0 0 40%;
    text-align: left;
    padding-right: 100px;
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.headshot-container {
    position: relative;
    width: 300px;
    height: 300px;
    margin-bottom: 30px;
}

.headshot-container::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 50%;
    background: linear-gradient(45deg, #ff4d5a, #3498db);
    z-index: 0;
}

.headshot {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.left p {
    font-size: 1.2rem;
    line-height: 1.4;
    color: #fafafa;
    max-width: 400px;
    margin-left: -20px;
    font-family: 'Montserrat', sans-serif;
}

.right {
    flex: 0 0 55%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
}

.skill-item {
    position: relative;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
}

.skill-item::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, #ff4d5a, #3498db);
    border-radius: 8px;
    z-index: 0;
}

.skill-item-content {
    position: relative;
    background-color: #1a1a1a;
    border-radius: 6px;
    padding: 15px;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.skill-item img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 20px;
}

.skill-item span {
    font-size: 1.2rem;
    font-weight: 600;
    color: #fafafa;
    font-family: 'Montserrat', sans-serif;
    line-height: 1;
    letter-spacing: 1px;
}

@media (max-width: 1200px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .left, .right {
        flex: 0 0 100%;
        padding-right: 0;
        margin-bottom: 40px;
    }

    .right {
        width: 100%;
    }

    .left p {
        max-width: 100%;
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .right {
        grid-template-columns: repeat(2, 1fr);
    }
}

body {
    font-family: 'Montserrat', sans-serif;
    background-color: #1a1a1a;
    color: #fafafa;
}