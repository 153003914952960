/* Importing the Poppins font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1a1a1a;
  background-size: cover;
  text-align: center;
  padding: 0 20px;
  font-family: 'Poppins', sans-serif; /* Set the font to Poppins */
  overflow: hidden; /* Ensure elements are hidden when animating in */
  border-bottom: 4px solid #0b0b0b;
}

.home h1 {
  font-size: 4rem; /* Increase the font size for better spacing */
  margin: 0;
  color: #ffffff;
  line-height: 1.1;
  font-weight: 300; /* Light font weight for a thinner look */
  opacity: 0; /* Start hidden */
  animation: slideInLeft 1s forwards; /* Slide in from the left */
  animation-delay: 0.1s; /* Delay the start of the animation */
}

.home .highlight {
  color: #ff4d5a;
  font-weight: 400; /* Slightly bolder to highlight the text */
}

.home p {
  font-size: 2.5rem; /* Slightly smaller font size for better balance */
  margin-top: 10px;
  color: #fafafa;
  line-height: 1.3;
  font-weight: 300; /* Light font weight to match the heading */
  opacity: 0; /* Start hidden */
  animation: slideInRight 1s forwards; /* Slide in from the right */
  animation-delay: 0.25s; /* Delay the start of the animation */
}

.home .btn {
  margin-top: 15px; /* Reduced space above the button */
  padding: 10px 20px; 
  background-color: transparent; 
  color: #ff4d5a;
  border: 2px solid #ff4d5a;
  text-decoration: none;
  border-radius: 3px; /* Reduce border-radius for a sharper look */
  font-size: 2rem; /* Slightly reduce font size for the button */
  transition: background-color 0.3s, color 0.3s;
  opacity: 0; /* Start hidden */
  animation: fadeInUp 1s forwards; /* Fade in from the bottom */
  animation-delay: 0.5s; /* Delay the start of the animation */
}

.home .btn:hover {
  background-color: #ff4d5a; 
  color: white; 
}

/* Keyframes for animations */

/* Slide in from left */
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Slide in from right */
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Fade in from bottom */
@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}








