.contact {
  padding: 100px 20px;
  background-color: #1a1a1a;
  color: white;
  text-align: center;
  position: relative; 
  border-bottom: 4px solid #070505;
}

.email-link {
  color: #ff4d5a; 
  font-size: 1.2rem;
  margin: 20px 0;
  display: inline-block;
}

.github-icon-container {
  position: absolute;
  bottom: 10px; 
  width: 100%;
  text-align: center;
}

.github-icon-container img {
  width: 50px; 
  opacity: 0.7; 
  transition: opacity 0.3s;
}

.github-icon-container img:hover {
  opacity: 1; 
}

  