.projects {
  padding: 100px 20px;
  background-color: #1a1a1a;
  color: white;
  text-align: center;
  border-bottom: 4px solid #070506f6;
}

.projects h2 {
  margin-bottom: 60px;
  font-size: 3rem;
  position: relative;
  display: inline-block;
}

.projects h2::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 4px;
  background-color: #ff4d5a;
}

.project-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
}

.project-item {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.project-item.left {
  flex-direction: row;
}

.project-item.right {
  flex-direction: row-reverse;
}

.project-image-container {
  flex: 0 0 60%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.project-image {
  width: 100%;
  height: auto;
  display: block;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.learn-more {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.project-image-container:hover {
  transform: scale(0.95);
}

.project-image-container:hover .project-overlay {
  opacity: 1;
}

.learn-more:hover {
  background-color: white;
  color: #1a1a1a;
}

.project-info {
  flex: 0 0 40%;
  padding: 0 40px;
  text-align: left;
}

.project-info h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ff4d5a;
}

.project-info p {
  font-size: 1.1rem;
  line-height: 1.6;
}

@media (max-width: 1024px) {
  .project-item {
    flex-direction: column;
  }

  .project-item.right {
    flex-direction: column;
  }

  .project-image-container,
  .project-info {
    flex: 0 0 100%;
  }

  .project-info {
    padding: 40px 0;
    text-align: center;
  }
}
  
  