body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
